import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Typography,
  CircularProgress,
  Pagination,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
} from "@mui/material";
import { FaDownload, FaSearch } from "react-icons/fa";
import Grid from "@mui/material/Grid2";
import CustomTextField from "../Util/CustomTextField";
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/Download";

export default function ActiveUsers() {
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [activities, setActivities] = useState(null);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const today = new Date();
    const formattedDate = `${today.getFullYear()}-${(today.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`;
    setStart(formattedDate);
    setEnd(formattedDate);
  }, []);

  useEffect(() => {
    if (start !== "" && end !== "") {
      getData();
    }
  }, [start, end, refresh, offset]);

  const multipliedOffset = offset * 12;

  function getData() {
    setLoading(true);

    fetch(`/api/logs/today/${start}/${end}/${multipliedOffset}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("Failed!");
      })
      .then((data) => {
        setActivities(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }

  function search(q) {
    setLoading(true);
    fetch(`/api/logs/search/${q}/${multipliedOffset}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        setActivities(data);
      })
      .catch(() => {
        setLoading(false);
      });
  }

  function downloadData() {
    if (activities && activities.data) {
      const csvData = convertToCSV(activities.data);
      downloadCSV(csvData);
    }
  }

  function convertToCSV(data) {
    const headers = ["No", "Name", "Phone", "Day", "Login Time"];
    const rows = data.map((item, index) => [
      multipliedOffset + index + 1,
      item.Name,
      item.Phone,
      item.Day,
      item.LoginTime,
    ]);

    const csvContent =
      "data:text/csv;charset=utf-8," +
      [headers, ...rows].map((e) => e.join(",")).join("\n");

    return csvContent;
  }

  function downloadCSV(csvContent) {
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "activities.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Logged In Users
      </Typography>

      <Box sx={{ marginTop: "2rem" }}>
        <Grid
          container
          alignItems="center"
          spacing={2}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid item xs={12} md={6}>
            <CustomTextField
              label="Search"
              placeholder="Task | Officer | Supervisor"
              onChange={(e) => {
                if (e.target.value !== "") {
                  search(e.target.value);
                } else {
                  setRefresh(!refresh);
                }
              }}
              backgroundColor="#feece2"
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", justifyContent: "flex-end", gap: "1rem" }}
          >
            <CustomTextField
              label="Start Date"
              type="date"
              value={start}
              onChange={(e) => setStart(e.target.value)}
              InputLabelProps={{ shrink: true }}
              backgroundColor="#feece2"
            />
            <CustomTextField
              label="End Date"
              type="date"
              value={end}
              onChange={(e) => setEnd(e.target.value)}
              InputLabelProps={{ shrink: true }}
              backgroundColor="#feece2"
            />
            <IconButton onClick={downloadData}>
              <DownloadIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
          <CircularProgress />
        </Box>
      )}
      {!loading && activities && (
        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>No.</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Day</TableCell>
                <TableCell>Login Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activities.data.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor: index % 2 === 0 ? "#feece2" : "#fff",
                  }}
                >
                  <TableCell>{multipliedOffset + index + 1}</TableCell>
                  <TableCell>{capitalizeFirstLetter(item?.Name)}</TableCell>
                  <TableCell>{item?.Phone}</TableCell>
                  <TableCell>{item?.Day}</TableCell>
                  <TableCell>{item?.LoginTime}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Box sx={{ marginTop: 2 }}>
        <Pagination
          count={Math.ceil(activities?.total / 12)}
          page={offset}
          onChange={(event, value) => setOffset(value)}
        />
      </Box>
    </Box>
  );
}
function capitalizeFirstLetter(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
}
