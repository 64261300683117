import { useEffect, useState } from "react";
import Pagination from "../Util/Pagination";
import WaveLoading from "../Util/WaveLoading";
import ReportItem from "./ReportItem";
import { Box, Input, TextField, IconButton, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Grid from "@mui/material/Grid2";
import CustomTextField from "../Util/CustomTextField";
import DownloadIcon from "@mui/icons-material/Download";

export default function Reports(props) {
  const [start, setStart] = useState("2024-01-01");
  const [end, setEnd] = useState("2024-12-31");
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  
 const multipliedOffset = offset * 12;

 function downloadData() {
   if (data && data.data) {
     const csvData = convertToCSV(data.data);
     downloadCSV(csvData);
   }
 }

 function convertToCSV(data) {
   const headers = ["No", "Task", "Type", "Date"];
   const rows = data.map((item, index) => [
     multipliedOffset + index + 1,
     item.Task,
     item.Type,
     item.Date,
   ]);

   const csvContent =
     "data:text/csv;charset=utf-8," +
     [headers, ...rows].map((e) => e.join(",")).join("\n");

   return csvContent;
 }

 function downloadCSV(csvContent) {
   const encodedUri = encodeURI(csvContent);
   const link = document.createElement("a");
   link.setAttribute("href", encodedUri);
   link.setAttribute("download", "DailyActivities.csv");
   document.body.appendChild(link);
   link.click();
   document.body.removeChild(link);
 }


  useEffect(() => {
    setLoading(true);
    fetch(`/api/activity/paginated/${start}/${end}/${offset * 12}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        console.log(data);

        setLoading(false);
        setData(data);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [refresh, offset, start, end]);

  function searchReport(query) {
    setLoading(true);
    fetch(`/api/activity/search/${query}/${offset * 12}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        setData(data);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  return (
    <Box className="list" sx={{ p: 2 }}>
      <Box className="myreports" sx={{ p: 2 }}>
        <Box sx={{ marginTop: "2rem" }}>
          <Grid
            container
            alignItems="center"
            spacing={2}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid item xs={12} md={6}>
              <CustomTextField
                label="Search"
                placeholder="Task | Officer | Supervisor"
                onChange={(e) => {
                  if (e.target.value !== "") {
                    searchReport(e.target.value);
                  } else {
                    setRefresh(!refresh);
                  }
                }}
                backgroundColor="#feece2"
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  ),
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              sx={{ display: "flex", justifyContent: "flex-end", gap: "1rem" }}
            >
              <CustomTextField
                label="Start Date"
                type="date"
                value={start}
                onChange={(e) => setStart(e.target.value)}
                InputLabelProps={{ shrink: true }}
                backgroundColor="#feece2"
              />
              <CustomTextField
                label="End Date"
                type="date"
                value={end}
                onChange={(e) => setEnd(e.target.value)}
                InputLabelProps={{ shrink: true }}
                backgroundColor="#feece2"
              />
              <IconButton onClick={downloadData}>
                <DownloadIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
        <Box className="my_list">
          {data && data?.data?.length > 0 ? (
            data.data.map((item, i) => (
              <ReportItem key={i} index={offset + i + 1} item={item} />
            ))
          ) : (
            <Typography variant="body1" color="textSecondary">
              No reports available
            </Typography>
          )}
        </Box>
        <Pagination
          totalItems={data ? data.total : 0}
          currentPage={offset}
          onPageChange={(v) => setOffset(v)}
        />
        {loading && <WaveLoading />}
      </Box>
    </Box>
  );
}
