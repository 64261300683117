import React, { useState, useEffect, useRef } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledSelect = styled(Select)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#bbc1c6",
    },
    "&:hover fieldset": {
      borderColor: "#028e37",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#028e37",
      borderWidth: "2px !important",
    },
  },
}));

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  color: "#061e06",
  "&.Mui-focused": {
    color: "#028e37",
  },
}));

export default function CustomSelect({
  label,
  data,
  value,
  onChange,
  disabled,
}) {
  const [selectedValue, setSelectedValue] = useState(value);
  const inputRef = useRef(null);

  useEffect(() => {
    if (data.length > 0 && !data.includes(selectedValue)) {
      setSelectedValue(data[0]);
    }
  }, [data, value]);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <FormControl fullWidth margin="normal">
      <StyledInputLabel>{label}</StyledInputLabel>
      <StyledSelect
        value={selectedValue}
        onChange={handleChange}
        ref={inputRef}
        label={label}
        disabled={disabled}
      >
        {data.map((item, index) => (
          <MenuItem key={index} value={item}>
            {item}
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
}
