import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  CircularProgress,
  Typography,
  IconButton,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import CustomTextField from "../Util/CustomTextField";
import CustomSelect from "../Util/CustomSelect";

const NewPortalUsers = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    fname: "",
    sname: "",
    email: "",
    phone: "",
    dpt: "",
    pos: "",
    level: "",
    levelname: "",
    role: "",
    gender: "",
    password: "",
    cpassword: "",
  });
  const [levelNames, setLevelNames] = useState([]);
  const [roles, setRoles] = useState([]);

  const clevel = formData.level;

  const gender = ["Female", "Male"];

  const directorate = ["Agriculture", "Livestock, Veterinary and Fisheries"];

  useEffect(() => {
    switch (clevel) {
      case "County":
        setLevelNames(["Select", "Kirinyaga"]);
        setRoles([
          "County Executive Committee Member",
          "Chief Officer",
          "County Director Agriculture",
          "County Director Livestock Veterinary and Fisheries",
          "CECM Office Administrator",
          "County SMS",
          "Head of Department",
          "CO Office Administrator",
          "Fleet Manager",
          "Head of CASIMU",
          "ICT/Admin",
          "Office Administrator",
          "Driver",
          "Support Staff",
        ]);
        break;
      case "Sub County":
        setLevelNames([
          "Kirinyaga East",
          "Kirinyaga West",
          "Mwea East",
          "Mwea West",
          "Kirinyaga Central",
        ]);
        setRoles([
          "SCAO",
          "SCLPO",
          "SCVO",
          "SCFO",
          "Office Administrator",
          "Drivers",
          "Support Staff",
        ]);
        break;
      case "Ward":
        setLevelNames([
          "Mutithi",
          "Kangai",
          "Wamumu",
          "Nyangati",
          "Murindiko",
          "Gathigiririr",
          "Teberer",
          "Kabare Baragwi",
          "Njukiini",
          "Ngariama",
          "Karumandi",
          "Mutira",
          "Kanyekini",
          "Kerugoya",
          "Inoi",
          "Mukure",
          "Kiine",
          "Kariti",
        ]);
        setRoles(["WAEO", "WLPO", "WAHO", "Meat Inspector"]);
        break;
      default:
        setLevelNames([]);
        setRoles([]);
    }
  }, [clevel]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const createUser = () => {
    const {
      fname,
      sname,
      email,
      phone,
      dpt,
      pos,
      level,
      levelname,
      role,
      gender,
      password,
      cpassword,
    } = formData;

    const body = {
      Name: `${titleCase(fname.trim())} ${titleCase(sname.trim())}`,
      Phone: phone,
      Email: email.toLowerCase().trim(),
      Role: role,
      Position: pos,
      Directorate: dpt,
      Level: level,
      LevelName: levelname,
      Gender: gender,
      Password: password,
    };

    setError("");

    const validateForm = () => {
      let result = true;
      if (!validateEmail(body.Email)) {
        setError("Please Enter a valid email address!");
        result = false;
      } else if (
        !validatePassword(body.Password) ||
        !validatePassword(cpassword)
      ) {
        setError("Password must be at least 6 characters!");
        result = false;
      } else if (body.Password !== cpassword) {
        setError("Passwords do not match!!!");
        result = false;
      } else if (!phone || phone.length !== 10) {
        setError("Enter a valid phone number");
        result = false;
      } else if (!fname || !sname) {
        setError("Two names are required!");
        result = false;
      }

      return result;
    };

    if (validateForm()) {
      setLoading(true);
      fetch(`/api/auth/register`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => response.ok && response.json())
        .then((data) => {
          setLoading(false);
          if (data.success) {
            setError(data.success);
            setTimeout(() => {
              props.setClicked(false);
              props.setRefresh(!props.refresh);
            }, 2000);
          } else {
            setError(data.error);
          }
        })
        .catch(() => setLoading(false));
    }
  };

  const validateEmail = (email) =>
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );

  const validatePassword = (password) => password.length >= 6;

  const titleCase = (str) =>
    str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());

  return (
    <Dialog open={true} onClose={() => props.setClicked(false)} fullWidth>
      <DialogTitle>
        New User
        <IconButton
          onClick={() => props.setClicked(false)}
          sx={{ position: "absolute", top: 8, right: 8, color: "#ffa726" }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CustomTextField
              name="fname"
              value={formData.fname}
              onChange={handleChange}
              label="First Name *"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTextField
              name="sname"
              value={formData.sname}
              onChange={handleChange}
              label="Surname *"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTextField
              name="email"
              value={formData.email}
              onChange={handleChange}
              label="Email *"
              type="email"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTextField
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              label="Phone *"
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomSelect
              label="Directorate *"
              data={directorate}
              value={formData.dpt}
              onChange={(value) =>
                handleChange({ target: { name: "dpt", value } })
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTextField
              name="pos"
              value={formData.pos}
              onChange={handleChange}
              label="Position *"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Level *</InputLabel>
              <Select
                name="level"
                value={formData.level}
                onChange={handleChange}
                label="Level *"
              >
                <MenuItem value="County">County</MenuItem>
                <MenuItem value="Sub County">Sub County</MenuItem>
                <MenuItem value="Ward">Ward</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomSelect
              label="Level Name *"
              data={levelNames}
              value={formData.levelname}
              onChange={(value) =>
                handleChange({ target: { name: "levelname", value } })
              }
              disabled={!formData.level}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomSelect
              label="Role *"
              data={roles}
              value={formData.role}
              onChange={(value) =>
                handleChange({ target: { name: "role", value } })
              }
              disabled={!formData.levelname}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomSelect
              label="gender *"
              data={gender}
              value={formData.gender}
              onChange={(value) =>
                handleChange({ target: { name: "gender", value } })
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTextField
              name="password"
              value={formData.password}
              onChange={handleChange}
              label="Password *"
              type="password"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTextField
              name="cpassword"
              value={formData.cpassword}
              onChange={handleChange}
              label="Confirm Password *"
              type="password"
            />
          </Grid>
          {error && (
            <Grid item xs={12}>
              <Typography color="error">{error}</Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            p: 2,
          }}
        >
          <Button
            onClick={createUser}
            variant="contained"
            sx={{
              backgroundColor: "#061e06",
              width: "80%",
              color: "#ffffff",
              "&:hover": {
                backgroundColor: "#061e06",
              },
            }}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Create User"
            )}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default NewPortalUsers;
