import React, { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import PortalUsers from "./PortalUsers";
import MUsers from "./MUsers";

export default function UserHome() {
  const [activeTab, setActiveTab] = useState("Portal Users");

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box sx={{ width: "100%", bgcolor: "white" }}>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        variant="standard"
        textColor="inherit"
        indicatorColor="primary"
        sx={{
          "& .MuiTabs-indicator": {
            backgroundColor: "rgba(255, 167, 38, 0.9)",
          },
        }}
      >
        <Tab
          label="Portal Users"
          value="Portal Users"
          sx={{
            display: "inline-flex",
            width: "auto",
            // bgcolor: activeTab === "Portal Users" ? "#ffa726" : "transparent",
            // color: activeTab === "Portal Users" ? "#028e37" : "black",
            "&:hover": {
              bgcolor: "#ffa726",
            },
            borderRadius: 1,
            p: 1,
          }}
        />
        <Tab
          label="Mobile Users"
          value="Mobile Users"
          sx={{
            display: "inline-flex",
            width: "auto",
            // bgcolor: activeTab === "Mobile Users" ? "#ffa726" : "transparent",
            // color: activeTab === "Mobile Users" ? "#028e37" : "black",
            "&:hover": {
              bgcolor: "#ffa726",
            },
            borderRadius: 1,
            p: 1,
          }}
        />
      </Tabs>

      <Box sx={{ p: 3 }}>
        {activeTab === "Portal Users" && <PortalUsers activeTab={activeTab} />}
        {activeTab === "Mobile Users" && <MUsers activeTab={activeTab} />}
      </Box>
    </Box>
  );
}
