import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import {
  Box,
  Typography,
  TextField,
  IconButton,
  CircularProgress,
  Divider,
  Grid,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faTimes,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import Button from "../Util/Button";
import Pagination from "../Util/Pagination";
import UserSelect from "./UserSelect";
import SelectedUser from "./SelectedUser";
import UserBox from "./UserBox";
import WaveLoading from "../Util/WaveLoading";
import NewPortalUsers from "./NewPortalUsers";

export default function PortalUsers(props) {
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userID, setUserID] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const [isFocused, setIsFocused] = useState(false); // State to track focus
  const selected = useRef();

  useEffect(() => {
    setLoading(true);
    fetch(`/api/auth/paginated/${offset * 12}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => res.ok && res.json())
      .then((data) => {
        setLoading(false);
        setData(data);
        if (data.result.length > 0) {
          setUserID(data.result[0].UserID);
        }
      })
      .catch(() => setLoading(false));
  }, [refresh, offset]);

  useEffect(() => {
    if (userID) {
      setLoading(true);
      fetch(`/api/auth/${userID}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => res.ok && res.json())
        .then((data) => {
          setLoading(false);
          setUserDetails(data);
        })
        .catch(() => setLoading(false));
    }
  }, [userID]);

  const quickSearch = (value) => {
    setData(null);
    setLoading(true);
    fetch(`/api/auth/quicksearch/${value}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => res.ok && res.json())
      .then((data) => {
        setLoading(false);
        setData(data);
        if (data.result.length > 0) {
          setUserID(data.result[0].UserID);
        }
      })
      .catch(() => setLoading(false));
  };

  return (
    <Box className="users" sx={{ padding: 2 }}>
      <Box className="list" sx={{ mb: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Portal Users
          </Typography>
          <IconButton
            onClick={() => setClicked(true)}
            sx={{ color: "#028e37" }}
          >
            <FontAwesomeIcon icon={faUserPlus} />
          </IconButton>
          <Box sx={{ ml: 2, display: "flex", alignItems: "center" }}>
            <TextField
              variant="outlined"
              placeholder="Name..."
              onChange={(e) => {
                const value = e.target.value;
                if (value) {
                  quickSearch(value);
                } else {
                  setRefresh(!refresh);
                }
              }}
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <FontAwesomeIcon icon={faSearch} />
                  </IconButton>
                ),
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#061e06", // default color
                  },
                  "&:hover fieldset": {
                    borderColor: "#028e37", // color on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#028e37", // color when clicked/focused
                  },
                },
              }}
            />
          </Box>
        </Box>

        <Divider sx={{marginBottom: "1rem"}}/>

        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 2,
              }}
            >
              {data?.result?.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    flex: "1 1 calc(50% - 16px)", // 50% width minus the gap
                    maxWidth: "calc(50% - 16px)", // 50% width minus the gap
                  }}
                >
                  <UserBox
                    item={item}
                    userID={userID}
                    setUserID={setUserID}
                    selected={selected}
                  />
                </Box>
              ))}
            </Box>
            {data && (
              <Pagination
                totalItems={data.total}
                currentPage={offset}
                onPageChange={setOffset}
              />
            )}
          </Grid>

          <Grid item xs={12} md={4}>
            <Box
              ref={selected}
              sx={{ border: "1px solid", borderRadius: 1, p: 2 }}
            >
              <Typography variant="h6">User Details</Typography>
              <Divider sx={{ my: 2 }} />
              {userDetails ? (
                <SelectedUser
                  setLoading={setLoading}
                  userDetails={userDetails}
                  setRefresh={setRefresh}
                  refresh={refresh}
                  url="auth"
                  activeTab={props.activeTab}
                />
              ) : (
                <Typography>Click on a user to see their details</Typography>
              )}
            </Box>
          </Grid>
        </Grid>

        {loading && <WaveLoading />}
      </Box>
      {clicked && (
        <NewPortalUsers
          setClicked={setClicked}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      )}
    </Box>
  );
}
