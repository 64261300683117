import React, { useState } from "react";
import { Box, Typography, IconButton, Divider } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import logo from "../../assets/imgs/logo.png";
import HomeIcon from "@mui/icons-material/Home";
import PieChartIcon from "@mui/icons-material/PieChart";
import BuildIcon from "@mui/icons-material/Build";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";

export default function Navigation(props) {
  const [hovered, setHovered] = useState(null);
  const location = useLocation();
  const history = useHistory();

  const handleClick = (url) => {
    if (url === "/logout") {
      localStorage.clear();
      history.push("/login");
    } else {
      history.push(url);
    }
  };

  const Item = ({ url, icon, text, showing }) => {
    const isActive = location.pathname === url;

    return (
      <Box
        onMouseEnter={() => setHovered(url)}
        onMouseLeave={() => setHovered(null)}
        onClick={() => handleClick(url)}
        sx={{
          backgroundColor: isActive
            ? "#028e37"
            : hovered === url
            ? "#60606010"
            : "transparent",
          display: "flex",
          alignItems: "center",
          padding: "0.5rem",
          cursor: "pointer",
          borderRadius: "5px",
        }}
      >
        <IconButton
          sx={{
            color: isActive ? "white" : hovered === url ? "white" : "#061e06",
            "&:hover": {
              color: "white",
            },
          }}
        >
          {icon}
        </IconButton>
        {showing && (
          <Typography
            sx={{
              marginLeft: 1,
              color: isActive ? "white" : hovered === url ? "white" : "#061e06",
              "&:hover": {
                color: "white",
              },
            }}
          >
            {text}
          </Typography>
        )}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        width: props.showing ? "250px" : "fit-content",
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        borderRight: "1px solid #e0e0e0",
        position: "fixed",
        top: 0,
        left: 0,
      }}
    >
      <Box
        sx={{
          padding: 2,
          display: "flex",
          alignItems: "center",
        }}
      >
        <img
          src={logo}
          alt="Logo"
          style={{ maxWidth: props.showing ? "70px" : "30px" }}
        />
        {props.showing && (
          <Box sx={{ marginLeft: 2 }}>
            <Typography variant="h6">KiriAMIS</Typography>
            <Typography variant="body2">Staff Portal</Typography>
          </Box>
        )}
      </Box>
      <Divider />
      <Item url="/" icon={<HomeIcon />} text="Home" showing={props.showing} />
      <Item
        url="/activities"
        icon={<PieChartIcon />}
        text="Daily Activities"
        showing={props.showing}
      />
      <Item
        url="/es"
        icon={<BuildIcon />}
        text="Extension Services"
        showing={props.showing}
      />
      <Item
        url="/portal-updates"
        icon={<NewspaperIcon />}
        text="Portal Updates"
        showing={props.showing}
      />
      <Item
        url="/users"
        icon={<PersonIcon />}
        text="Users"
        showing={props.showing}
      />
      <Item
        url="/settings"
        icon={<SettingsIcon />}
        text="Settings"
        showing={props.showing}
      />
    </Box>
  );
}
