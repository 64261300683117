import React, { useEffect, useState, forwardRef } from "react";
import {
  Box,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Pagination from "../Util/Pagination";
import CustomTextField from "../Util/CustomTextField";
import WaveLoading from "../Util/WaveLoading";
import Grid from "@mui/material/Grid2";
import DownloadIcon from "@mui/icons-material/Download";

const ActivitiesTable = forwardRef((props, ref) => {
  const today = new Date();
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activities, setActivities] = useState(null);
  const [start, setStart] = useState(
    `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(today.getDate()).padStart(2, "0")}`
  );
  const [end, setEnd] = useState(
    `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(today.getDate()).padStart(2, "0")}`
  );
  const [offset, setOffset] = useState(0);

  const multipliedOffset = offset * 12;

  function downloadData() {
    if (activities && activities.data) {
      console.log(activities.data);

      const csvData = convertToCSV(activities.data);
      downloadCSV(csvData);
    }
  }

  function convertToCSV(data) {
    const headers = [
      "No",
      "Task",
      "Venue",
      "Name",
      "Phone",
      "Date",
      "Level",
      "LevelName",
    ];
    const rows = data?.map((item, index) => [
      multipliedOffset + index + 1,
      item.Task,
      item.Venue,
      item.Name,
      item.Phone,
      item.Date,
      item.Level,
      item.LevelName,
    ]);

    const csvContent =
      "data:text/csv;charset=utf-8," +
      [headers, ...rows].map((e) => e.join(",")).join("\n");

    return csvContent;
  }

  function downloadCSV(csvContent) {
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "Activities.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  useEffect(() => {
    setLoading(true);
    fetch(`/api/activity/paginated/${start}/${end}/${offset * 12}`)
      .then((res) => (res.ok ? res.json() : Promise.reject()))
      .then((data) => {
        console.log(data);
        setLoading(false);
        setActivities(data);
      })
      .catch(() => setLoading(false));
  }, [refresh, offset, start, end]);

  function search(q) {
    setLoading(true);
    fetch(`/api/activity/search/${q}/${offset * 12}`)
      .then((res) => (res.ok ? res.json() : Promise.reject()))
      .then((data) => {
        setLoading(false);
        setActivities(data);
      })
      .catch(() => setLoading(false));
  }

  return (
    <Box ref={ref} className="landing">
      <Box>
        <Box sx={{ marginTop: "2rem" }}>
          <Grid
            container
            alignItems="center"
            spacing={2}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid item xs={12} md={6}>
              <CustomTextField
                label="Search"
                placeholder="Task | Officer | Supervisor"
                onChange={(e) => {
                  if (e.target.value !== "") {
                    search(e.target.value);
                  } else {
                    setRefresh(!refresh);
                  }
                }}
                backgroundColor="#feece2"
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  ),
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              sx={{ display: "flex", justifyContent: "flex-end", gap: "1rem" }}
            >
              <CustomTextField
                label="Start Date"
                type="date"
                value={start}
                onChange={(e) => setStart(e.target.value)}
                InputLabelProps={{ shrink: true }}
                backgroundColor="#feece2"
              />
              <CustomTextField
                label="End Date"
                type="date"
                value={end}
                onChange={(e) => setEnd(e.target.value)}
                InputLabelProps={{ shrink: true }}
                backgroundColor="#feece2"
              />
              <IconButton onClick={downloadData}>
                <DownloadIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "6.6%", fontWeight: "bolder" }}>
                No.
              </TableCell>
              <TableCell sx={{ width: "28.6%", fontWeight: "bolder" }}>
                Task & Venue
              </TableCell>
              <TableCell sx={{ width: "20.6%", fontWeight: "bolder" }}>
                Officer
              </TableCell>
              <TableCell sx={{ width: "14.0%", fontWeight: "bolder" }}>
                Date
              </TableCell>
              <TableCell sx={{ width: "12.6%", fontWeight: "bolder" }}>
                Level
              </TableCell>
              <TableCell sx={{ width: "12.6%", fontWeight: "bolder" }}>
                Level Name
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activities &&
              activities?.data?.length > 0 &&
              activities.data.map((item, i) => (
                <TableRow key={i}>
                  <TableCell sx={{ width: "6.6%" }}>
                    {multipliedOffset + i + 1}
                  </TableCell>
                  <TableCell sx={{ width: "28.6%" }}>
                    <Typography
                      variant="body2"
                      sx={{ color: "#028e37", fontWeight: "800" }}
                    >
                      {capitalizeFirstLetter(item.Task)}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {capitalizeFirstLetter(item.Venue)}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ width: "20.6%" }}>
                    <Typography variant="body2">
                      {capitalizeFirstLetter(item.Name)}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {item.Phone}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ width: "14.0%" }}>{item.Date}</TableCell>
                  <TableCell sx={{ width: "12.6%" }}>{item.Level}</TableCell>
                  <TableCell sx={{ width: "12.6%" }}>
                    {item.LevelName}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        totalItems={activities ? activities.total : 0}
        currentPage={offset}
        onPageChange={(v) => setOffset(v)}
      />
      {loading && <WaveLoading />}
    </Box>
  );
});

function capitalizeFirstLetter(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
}

export default ActivitiesTable;
