import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

export default function UserBox(props) {
  const [init, setInit] = useState("");

  useEffect(() => {
    const nm = props.item.Name.split(" ");
    if (nm.length > 1) {
      setInit(nm[0].substring(0, 1) + nm[1].substring(0, 1));
    } else setInit(nm[0].substring(0, 1));
  }, [props.item.Name]);

  return (
    <Box
      className="list-items"
      sx={{
        border: "1px solid #ccc",
        borderRadius: 1,
        p: 2,
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "#f5f5f5",
        },
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
      onClick={() => {
        props.setUserID(props.item.UserID);
        props.selected?.current.scrollIntoView({ behavior: "smooth" });
      }}
    >
      <Box
        sx={{
          backgroundColor: props.item.Status ? "#028e37" : "orange",
          color: "#fff",
          borderRadius: "50%",
          width: 40,
          height: 40,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mr: 2,
        }}
      >
        <Typography variant="h6">{init}</Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="h6">{props.item.Name}</Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ display: "flex", alignItems: "center", mt: 0.5 }}
        >
          <FontAwesomeIcon
            icon={faEnvelope}
            style={{ marginRight: 8 }}
            color="#ffa726"
          />
          {props.item.Email}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ display: "flex", alignItems: "center", mt: 0.5 }}
        >
          <FontAwesomeIcon
            icon={faPhone}
            style={{ marginRight: 8 }}
            color="#ffa726"
          />
          {props.item.Phone}
        </Typography>
      </Box>
    </Box>
  );
}
