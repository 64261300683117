import { useEffect, useRef, useState } from "react";
import jwt from "jsonwebtoken";
import { Button, TextField, Typography, Box, Divider } from "@mui/material";
import Grid from "@mui/material/Grid2";
import "../../Styles/settings.scss";

export default function Settings(props) {
  const [currentUser, setCurrentUser] = useState("");
  const [error, setError] = useState("");
  const psd = useRef();
  const npsd = useRef();
  const cpsd = useRef();

  useEffect(() => {
    const token = localStorage.getItem("gdfhgfhtkn");

    if (token) {
      try {
        var decoded = jwt.decode(token);
        if (Date.now() >= decoded.exp * 1000) {
          window.location.href = "/login";
        } else {
          setCurrentUser(decoded);
        }
      } catch (error) {
        window.location.href = "/login";
      }
    } else {
      window.location.href = "/login";
    }
  }, []);

  function changePassword() {
    if (
      cpsd.current.value === "" ||
      npsd.current.value === "" ||
      psd.current.value === ""
    ) {
      return setError("All fields are required!");
    }
    if (cpsd.current.value !== npsd.current.value) {
      return setError("Passwords do not match!");
    }
    let body = {
      Password: psd.current.value,
      NewPassword: npsd.current.value,
    };
    fetch(`/api/auth/${currentUser.UserID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            localStorage.clear();
            window.location.href = "/login";
          }, 1000);
        } else setError(data.error);
      })
      .catch((e) => {});
  }

  function convertTime(dt) {
    const date = new Date(dt * 1000);
    return date.toString();
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5">User Details</Typography>
      <Divider />
      <Box sx={{ flexGrow: 1, mt: 2 }}>
        <Grid container spacing={2} columns={16} className="details">
          <Grid item xs={8} className="details__left">
            {currentUser !== "" && (
              <>
                {currentUser.UserID && (
                  <Typography>
                    <strong>UserID: </strong>
                    {currentUser.UserID}
                  </Typography>
                )}
                {currentUser.Email && (
                  <Typography>
                    <strong>Email: </strong>
                    {currentUser.Email}
                  </Typography>
                )}
                {currentUser.Department && (
                  <Typography>
                    <strong>Department: </strong>
                    {currentUser.Department}
                  </Typography>
                )}
                {currentUser.Level && (
                  <Typography>
                    <strong>Level: </strong>
                    {currentUser.Level}
                  </Typography>
                )}
                {currentUser.Role && (
                  <Typography>
                    <strong>Role: </strong>
                    {currentUser.Role}
                  </Typography>
                )}
                {currentUser.FirstTimeLogin !== undefined && (
                  <Typography>
                    <strong>First Time Login: </strong>
                    {currentUser.FirstTimeLogin ? "Yes" : "No"}
                  </Typography>
                )}
                {currentUser.exp && (
                  <Typography>
                    <strong>Logout Time: </strong>
                    {convertTime(currentUser.exp).split("GMT")[0]}
                  </Typography>
                )}
              </>
            )}
          </Grid>
          <Grid item xs={8} className="details__right">
            {currentUser !== "" && (
              <>
                {currentUser.Name && (
                  <Typography>
                    <strong>Name: </strong>
                    {currentUser.Name}
                  </Typography>
                )}
                {currentUser.Position && (
                  <Typography>
                    <strong>Position: </strong>
                    {currentUser.Position}
                  </Typography>
                )}
                {currentUser.Status && (
                  <Typography>
                    <strong>Status: </strong>
                    {currentUser.Status}
                  </Typography>
                )}
                {currentUser.LevelName && (
                  <Typography>
                    <strong>Level Name: </strong>
                    {currentUser.LevelName}
                  </Typography>
                )}
                {currentUser.Phone && (
                  <Typography>
                    <strong>Phone: </strong>
                    {currentUser.Phone}
                  </Typography>
                )}
                {currentUser.iat && (
                  <Typography>
                    <strong>Login Time: </strong>
                    {convertTime(currentUser.iat).split("GMT")[0]}
                  </Typography>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h5">Change Password</Typography>
        <Divider />
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <TextField
            inputRef={psd}
            label="Old Password"
            type="password"
            variant="outlined"
            fullWidth
            sx={{ mt: 2 }}
          />
          <TextField
            inputRef={npsd}
            label="New Password"
            type="password"
            variant="outlined"
            fullWidth
            sx={{ mt: 2 }}
          />
          <TextField
            inputRef={cpsd}
            label="Confirm Password"
            type="password"
            variant="outlined"
            fullWidth
            sx={{ mt: 2 }}
          />
          <Typography color="error" sx={{ mt: 2 }}>
            {error}
          </Typography>
          <Button
            variant="contained"
            sx={{
              mt: 2,
              backgroundColor: "#061e06",
              width: "80%",
              margin: "0 auto",
              display: "block",
              "&:hover": {
                backgroundColor: "#061e06",
              },
            }}
            onClick={changePassword}
          >
            Submit
          </Button>
        </form>
      </Box>
    </Box>
  );
}
