import { useRef, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

export default function NewProject() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const title = useRef();
  const image = useRef();
  const [type, setType] = useState("");
  const description = useRef();
  const link = useRef();

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const AddProject = () => {
    const body = {
      Title: title.current.value,
      Image: image.current.files[0],
      Type: type,
      Description: description.current.value,
      Link: link.current.value,
    };
    setLoading(true);
    setError("");

    const validateForm = () => {
      let result = true;
      if (!body.Title) {
        result = false;
        setError("Please enter a title!");
        setLoading(false);
        return result;
      }

      if (!body.Type) {
        result = false;
        setError("Type is required!");
        setLoading(false);
        return result;
      }
      if (!body.Description) {
        result = false;
        setError("Write a description!");
        setLoading(false);
        return result;
      }
      if (!body.Link) {
        result = false;
        setError("Link is required!");
        setLoading(false);
        return result;
      }
      return result;
    };

    if (validateForm()) {
      const formData = new FormData();

      for (const key in body) {
        formData.append(key, body[key]);
      }

      fetch(`/api/projects/create`, {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json",
        },
        body: formData,
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else throw Error("");
        })
        .then((data) => {
          setLoading(false);
          if (data.success) {
            setError(data.success);
          } else {
            setError(data.error);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" sx={{ color: "#028e37" }}>
        New Project
      </Typography>
      <hr />
      <Box
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
        }}
        sx={{ mt: 2 }}
      >
        <TextField
          fullWidth
          inputRef={title}
          label="Name"
          variant="outlined"
          required
          margin="normal"
        />

        <TextField
          fullWidth
          inputRef={image}
          type="file"
          label="Image"
          InputLabelProps={{ shrink: true }}
          inputProps={{ accept: "image/png, image/gif, image/jpeg" }}
          required
          margin="normal"
        />

        <FormControl fullWidth margin="normal">
          <InputLabel id="select-type-label">Type</InputLabel>
          <Select
            labelId="select-type-label"
            value={type}
            onChange={handleTypeChange}
            label="Type"
            required
          >
            <MenuItem value="Internal Projects">Internal Projects</MenuItem>
            <MenuItem value="Partner Projects">Partner Projects</MenuItem>
            <MenuItem value="National GVT Projects">
              National GVT Projects
            </MenuItem>
            <MenuItem value="Donor Projects">Donor Projects</MenuItem>
            <MenuItem value="NGOs Projects">NGOs Projects</MenuItem>
            <MenuItem value="Others">Others</MenuItem>
          </Select>
        </FormControl>

        <TextField
          fullWidth
          inputRef={description}
          label="Description"
          variant="outlined"
          multiline
          rows={4}
          required
          margin="normal"
        />

        <TextField
          fullWidth
          inputRef={link}
          label="Link"
          variant="outlined"
          required
          margin="normal"
        />

        {error && (
          <Typography color="error" variant="body2" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            p: 2,
          }}
        >
          <Button
            onClick={AddProject}
            variant="contained"
            sx={{
              backgroundColor: "#061e06",
              width: "80%",
              color: "#ffffff",
              "&:hover": {
                backgroundColor: "#061e06",
              },
            }}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Submit"
            )}
          </Button>
        </Box>

        {loading && <CircularProgress sx={{ mt: 2 }} />}
      </Box>
    </Box>
  );
}
