import "../../Styles/reports.scss";
import { useEffect, useState } from "react";
import ReportSelect from "./ReportsManagement/ReportSelect.jsx";
import RChart from "./RChart.jsx";
import CircleLoading from "../Util/CircleLoading.jsx";
import WaveLoading from "../Util/WaveLoading.jsx";

export default function Workplan(props) {
  const jwt = require("jsonwebtoken");
  const { getMonths } = require("../../assets/data/data.js");
  const yy = ["2023", "2024", "2025"];
  const [year, setYear] = useState("2023");
  const [today, setToday] = useState("");
  const [month, setMonth] = useState("January");
  const [mm, setMM] = useState([]);
  const [dd, setDD] = useState([]);

  const token = localStorage.getItem("gdfhgfhtkn");
  var decoded = jwt.decode(token);
  const level = decoded.Level.replace(" ", "");
  const levelname = decoded.LevelName;

  useEffect(() => {
    const date = new Date().toISOString().split("T")[0].split("-");
    setYear(date[0]);
    const d = new Date().toISOString().split("T")[0];
    setToday(d);
    const mi = getMonths
      .map((item) => {
        return item.code;
      })
      .indexOf(date[1]);
    if (mi != -1) {
      setMonth(getMonths[mi].month);
    }
  }, []);

  useEffect(() => {
    let d = [];
    getMonths.map((item) => {
      d.push(item["month"]);
    });
    setMM(d);
    const mi = getMonths
      .map((item) => {
        return item.month;
      })
      .indexOf(month);

    if (mi != -1) {
      let x = [];
      for (let index = 1; index < getMonths[mi].days + 1; index++) {
        if (index == 1) {
          const d = new Date(`${year}-${getMonths[mi].code}-01`);

          for (let index = 0; index < d.getDay(); index++) {
            x.push(null);
          }
          x.push({
            y: year,
            m: getMonths[mi].code,
            d: index < 8 ? "0" + index : index,
          });
        } else {
          x.push({
            y: year,
            m: getMonths[mi].code,
            d: index < 8 ? "0" + index : index,
          });
        }
      }
      if (x.length < 35) {
        const size = 35 - x.length;
        for (let index = 0; index < size; index++) {
          x.push(null);
        }
      }
      setDD(x);
    }
  }, [month, year]);

  return (
    <div className="list">
      <div className="options">
        <ReportSelect
          handleSelection={(e) => {
            setYear(e);
          }}
          value={year}
          data={yy}
        />
        <ReportSelect
          value={month}
          handleSelection={(e) => {
            setMonth(e);
          }}
          data={mm}
        />
      </div>
      <div className="dow">
        <h4>Sun</h4>
        <h4>Mon</h4>
        <h4>Tue</h4>
        <h4>Wed</h4>
        <h4>Thu</h4>
        <h4>Fri</h4>
        <h4>Sat</h4>
      </div>
      <div className="calendar">
        {dd.map((item, i) => {
          return (
            <Day
              key={i}
              item={item}
              today={today}
              level={level}
              levelname={levelname}
            />
          );
        })}
      </div>
    </div>
  );
}

const Day = (props) => {
  const [data, setData] = useState({
    total: 0,
    users: 0,
    chart: [
      { name: "Pending", value: 0 },
      { name: "Complete", value: 0 },
    ],
  });
  let d = "";
  const [loading, setLoading] = useState(false);
  const [showing, setShowing] = useState(false);
  useEffect(() => {
    if (props.item != null) {
      setLoading(true);
      setData(null);
      d = `${props.item.y}-${props.item.m}-${props.item.d}`;
      fetch(`/api/workplan/searchdate/${d}/${props.level}/${props.levelname}`)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else throw Error();
        })
        .then((data) => {
          setData(data);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [props.item]);

  return (
    <>
      {props.item == null ? (
        <div
          style={{
            backgroundColor: "#60606010",
          }}
          className="day mob"
        ></div>
      ) : (
        <div
          style={{
            backgroundColor:
              data && data?.total !== 0 ? "#ffebfe" : "#60606030",
            borderBottom:
              props.today == `${props.item.y}-${props.item.m}-${props.item.d}`
                ? "3px solid orange"
                : "3px solid #028e37",
          }}
          onClick={() => {
            if (data && data?.total > 0) {
              setShowing(true);
            }
          }}
          className="day"
        >
          <h6>
            {props.item.y}-{props.item.m}-{props.item.d}
          </h6>
          <h3 title="Number of workplans">
            <i className="fa fa-info-circle"></i> {data && data?.total}
          </h3>
          <p title="Number of field officers">
            <i className="fa fa-user"></i> {data && data?.users}
          </p>
          <div className="chart">{data && <RChart data={data?.chart} />}</div>
          {loading && <CircleLoading />}
        </div>
      )}

      {showing && (
        <Popup
          setShowing={setShowing}
          Pending={data.chart[0].value}
          Complete={data.chart[1].value}
          date={`${props.item.y}-${props.item.m}-${props.item.d}`}
          level={props.level}
          levelname={props.levelname}
        />
      )}
    </>
  );
};

const Popup = (props) => {
  const [isLoading, setIsLoading] = useState(null);
  const [cols, setCols] = useState(null);
  const [cls, setCls] = useState(null);
  const [data, setData] = useState(null);
  const [index, setIndex] = useState(-1);
  const [active, setActive] = useState("Pending");

  useEffect(() => {
    setData(null);
    setIsLoading(true);
    fetch(
      `/api/workplan/searchdetails/${active == "Pending" ? false : true}/${
        props.level
      }/${props.levelname}/${props.date}`
    )
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error();
      })
      .then((data) => {
        console.log(data);

        if (data.length > 0) {
          setData(data);
          setIndex(0);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }, [active]);

  const Bar = (params) => {
    return (
      <p
        onClick={() => {
          setActive(params.txt);
        }}
        className={active === params.txt ? "active" : ""}
      >
        {params.txt + ` (${params.Tally})`}
      </p>
    );
  };

  const WorkPlanItem = (params) => {
    return (
      <div
        style={{
          backgroundColor: params.index == index ? "white" : "#FFF7D4",
        }}
        className="witem"
        onClick={() => {
          setIndex(params.index);
        }}
      >
        <h4>{params.item.Task}</h4>
        <p>
          Location: {params.item.SubCounty},{params.item.Ward}
        </p>
        <p>
          Field Officer: {params.item.Name} - {params.item.Phone}
        </p>
      </div>
    );
  };

  const WDetails = (params) => {
    return (
      <div className="details">
        <h4>Work Plan Details</h4>
        <div className="section">
          <h5>Field Officer</h5>
          <p>{params.item.Name}</p>
          <p>{params.item.Phone}</p>
        </div>
        <div className="section">
          <h4>Work Plan Details</h4>
          <p>Task: {params.item.Task}</p>
          <p>Type: {params.item.Type}</p>
          <p>Sub County: {params.item.SubCounty}</p>
          <p>Ward: {params.item.Ward}</p>
          <p>Date: {params.item.Date}</p>
        </div>
        <div className="section">
          <h4>Field Officer Report</h4>
          <p>Remarks: {params.item.Remarks}</p>
          <p>Farmers Reached: {params.item.Tally}</p>
          <p>Farmer Name: {params.item?.FName}</p>
          <p>Farmer Phone: {params.item?.FPhone}</p>
          <p>Date: {params.item.updatedAt.split("T")[0]}</p>
        </div>
        <div className="section">
          <h4>Supervisor Report</h4>
          <p>Remarks: {params.item.SupervisorRemarks}</p>
          <p>Supervisor Name: {params.item?.SName}</p>
          <p>Supervisor Phone: {params.item?.SPhone}</p>
        </div>
      </div>
    );
  };

  return (
    <div data-aos="fade-left" className="popup">
      <div className="container">
        <div className="dets">
          <h3>{props?.date}</h3>

          <i
            onClick={() => {
              props.setShowing(null);
            }}
            className="fa fa-times"
          ></i>
        </div>
        <hr />
        <div className="rbar">
          <Bar txt="Pending" Tally={props.Pending} />
          <Bar txt="Complete" Tally={props.Complete} />
        </div>
        <div className="wcontent">
          <div className="div2equal">
            <div>
              {data &&
                data.length > 0 &&
                data.map((item, i) => {
                  return <WorkPlanItem key={i} index={i} item={item} />;
                })}
            </div>
            <div>{data && index != -1 && <WDetails item={data[index]} />}</div>
          </div>

          {isLoading && <WaveLoading />}
        </div>
      </div>
    </div>
  );
};
