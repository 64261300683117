import React from "react";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledTextField = styled(TextField)(({ theme, backgroundColor }) => ({
  backgroundColor: backgroundColor || "inherit",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#bbc1c6",
    },
    "&:hover fieldset": {
      borderColor: "#028e37",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#028e37",
    },
  },
  "& .MuiInputLabel-root": {
    color: "#061e06",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#028e37",
  },
}));

const CustomTextField = ({
  name,
  value,
  onChange,
  label,
  type = "text",
  placeholder,
  InputLabelProps,
  backgroundColor, // Add backgroundColor prop here
}) => (
  <StyledTextField
    name={name}
    value={value}
    onChange={onChange}
    label={label}
    type={type}
    placeholder={placeholder}
    backgroundColor={backgroundColor} // Pass it to StyledTextField
    fullWidth
    margin="normal"
    InputLabelProps={{
      ...InputLabelProps,
      shrink: value || InputLabelProps?.shrink,
    }}
  />
);

export default CustomTextField;
