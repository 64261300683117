import React, { useState, useEffect, useRef } from "react";
import "../../Styles/users.scss";
import {
  Box,
  Button,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Add, Search } from "@mui/icons-material";
import WaveLoading from "../Util/WaveLoading";
import Pagination from "../Util/Pagination";
import UserBox from "./UserBox";
import SelectedUser from "./SelectedUser";
import NewMobileUsers from "./NewMobileUsers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faUserPlus } from "@fortawesome/free-solid-svg-icons";

export default function MUsers(props) {
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [userID, setUserID] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const selected = useRef();

  useEffect(() => {
    setLoading(true);
    fetch(`/api/mobile/paginated/${offset * 12}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => res.ok && res.json())
      .then((data) => {
        setLoading(false);
        setData(data);
        if (data?.result?.length > 0) {
          setUserID(data.result[0].UserID);
        }
      })
      .catch(() => setLoading(false));
  }, [refresh]);

  useEffect(() => {
    selectedUser();
  }, [userID]);

  const selectedUser = () => {
    setLoading(true);
    fetch(`/api/mobile/${userID}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => res.ok && res.json())
      .then((data) => {
        setLoading(false);
        setUserDetails(data);
      })
      .catch(() => setLoading(false));
  };

  const quickSearch = (value) => {
    setLoading(true);
    fetch(`/api/mobile/quicksearch/${value}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => res.ok && res.json())
      .then((data) => {
        setLoading(false);
        setData(data);
        if (data?.result?.length > 0) {
          setUserID(data.result[0].UserID);
        }
      })
      .catch(() => setLoading(false));
  };

  return (
    <Box className="users" sx={{ padding: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Mobile Users
        </Typography>
        <IconButton onClick={() => setClicked(true)} sx={{ color: "#028e37" }}>
          <FontAwesomeIcon icon={faUserPlus} />
        </IconButton>
        <Box sx={{ ml: 2, display: "flex", alignItems: "center" }}>
          <TextField
            variant="outlined"
            placeholder="Name..."
            onChange={(e) => {
              const value = e.target.value;
              if (value) {
                quickSearch(value);
              } else {
                setRefresh(!refresh);
              }
            }}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <FontAwesomeIcon icon={faSearch} />
                </IconButton>
              ),
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#061e06",
                },
                "&:hover fieldset": {
                  borderColor: "#028e37",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#028e37",
                },
              },
            }}
          />
        </Box>
      </Box>
      <Divider sx={{ marginBottom: "1rem" }} />
      <Grid className="list__container" container spacing={2} columns={16}>
        <Grid className="list__box" size={12}>
          <Box className="user-list">
            {data?.result?.length > 0 &&
              data.result.map((item, index) => (
                <UserBox
                  key={index}
                  item={item}
                  userID={userID}
                  setUserID={setUserID}
                  selected={selected}
                />
              ))}
          </Box>
          {data && (
            <Pagination
              totalItems={data?.total}
              currentPage={offset}
              onPageChange={(v) => setOffset(v)}
            />
          )}
        </Grid>
        <Grid size={4}>
          <div className="selected">
            <Typography variant="h6">User Details</Typography>
            <Divider sx={{ mb: 2 }} />
            {userDetails ? (
              <SelectedUser
                setLoading={setLoading}
                userDetails={userDetails}
                setRefresh={setRefresh}
                refresh={refresh}
                url="mobile"
                activeTab={props.activeTab}
              />
            ) : (
              <Typography>No user selected</Typography>
            )}
          </div>
        </Grid>
      </Grid>
      {loading && <WaveLoading />}

      {/* Popup is rendered conditionally based on the 'clicked' state */}
      {clicked && (
        <NewMobileUsers
          setClicked={setClicked}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      )}
    </Box>
  );
}
