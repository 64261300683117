import { forwardRef, useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Divider,
  Button,
  Box,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// Styled components
const DetailsContainer = styled(Box)({
  display: "flex",
  gap: "16px",
});

const DetailsSection = styled(Box)({
  flex: 1,
});

const SectionTitle = styled(Typography)({
  fontWeight: "bold",
});

const Popup = forwardRef((props, ref) => {
  const [user, setUser] = useState(null);
  const [supervisor, setSupervisor] = useState(null);

  useEffect(() => {
    if (props.item) {
      fetch(`/api/mobile/${props.item.UserID}`)
        .then((res) => (res.ok ? res.json() : Promise.reject("Error")))
        .then((data) => setUser(data))
        .catch((e) => console.error(e));
    }
  }, [props.item]);

  useEffect(() => {
    if (props.item) {
      fetch(`/api/mobile/${props.item.SupervisorID}`)
        .then((res) => (res.ok ? res.json() : Promise.reject("Error")))
        .then((data) => setSupervisor(data))
        .catch((e) => console.error(e));
    }
  }, [props.item]);

  return (
    <Dialog
      open={Boolean(props.item)}
      onClose={() => props.setPopup(null)}
      fullWidth
      maxWidth="md"
      ref={ref}
    >
      <DialogTitle variant="h5" sx={{ color: "#028e37" }}>
        Workplan Details
        <IconButton
          edge="end"
          color="inherit"
          onClick={() => props.setPopup(null)}
          aria-label="close"
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DetailsContainer>
          <DetailsSection>
            <SectionTitle variant="h6" sx={{ color: "#028e37" }}>
              Details
            </SectionTitle>
            <Divider />
            <Typography variant="body2">
              <b>Task: </b>
              {props?.item?.Task}
            </Typography>
            <Typography variant="body2">
              <b>Type: </b>
              {props?.item?.Type}
            </Typography>
            <Typography variant="body2">
              <b>Date: </b>
              {props?.item?.Date}
            </Typography>
            <Typography variant="body2">
              <b>County: </b>
              {props?.item?.County}
            </Typography>
            <Typography variant="body2">
              <b>SubCounty: </b>
              {props?.item?.SubCounty}
            </Typography>
            <Typography variant="body2">
              <b>Ward: </b>
              {props?.item?.Ward}
            </Typography>
          </DetailsSection>
          <DetailsSection>
            <SectionTitle variant="h6" sx={{ color: "#028e37" }}>
              Officer
            </SectionTitle>
            <Divider />
            <Typography variant="body2">
              <b>Name: </b>
              {user ? user?.Name : ""}
            </Typography>
            <Typography variant="body2">
              <b>Phone: </b>
              {user ? user?.Phone : ""}
            </Typography>
            <Typography variant="body2">
              <b>Role: </b>
              {user ? user?.Role : ""}
            </Typography>
            <Typography variant="body2">
              <b>Target: </b>
              {props?.item?.Target}
            </Typography>
            <Typography variant="body2">
              <b>Achievement: </b>
              {props?.item?.Tally}
            </Typography>
            <Typography variant="body2">
              <b>Remarks: </b>
              {props?.item?.Remarks}
            </Typography>
          </DetailsSection>
          <DetailsSection>
            <SectionTitle variant="h6" sx={{ color: "#028e37" }}>
              Supervisor
            </SectionTitle>
            <Divider />
            <Typography variant="body2">
              <b>Name: </b>
              {supervisor ? supervisor?.Name : ""}
            </Typography>
            <Typography variant="body2">
              <b>Phone: </b>
              {supervisor ? supervisor?.Phone : ""}
            </Typography>
            <Typography variant="body2">
              <b>Role: </b>
              {supervisor ? supervisor?.Role : ""}
            </Typography>
            <Typography variant="body2">
              <b>Remarks: </b>
              {props?.item?.SupervisorRemarks}
            </Typography>
          </DetailsSection>
        </DetailsContainer>
      </DialogContent>
    </Dialog>
  );
});

export default Popup;
