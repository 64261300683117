import { useEffect, useState } from "react";
import Pagination from "../Util/Pagination";
import WaveLoading from "../Util/WaveLoading";
import { GrUserAdmin } from "react-icons/gr";
import { FaFish } from "react-icons/fa";
import { FaUserDoctor, FaWheatAwn } from "react-icons/fa6";
import { GiBullHorns } from "react-icons/gi";
import { styled } from "@mui/material/styles";
import {
  Box,
  Card,
  Typography,
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import SearchIcon from "@mui/icons-material/Search";
import CustomTextField from "../Util/CustomTextField";
import { FaDownload } from "react-icons/fa";
import DownloadIcon from "@mui/icons-material/Download";

export default function Reports(props) {
  const [start, setStart] = useState("2024-01-01");
  const [end, setEnd] = useState("");
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState(null);
  const [todaysData, setTodaysData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch(`/api/workplan/completed/${offset * 12}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        setData(data);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [refresh, offset]);

  useEffect(() => {
    setLoading(true);
    fetch(`/api/workplan/completed/today/${offset * 12}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        setTodaysData(data);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [refresh, offset]);

  function searchReport(q) {
    setLoading(true);
    fetch(`/api/workplan/searchcompleted/${q}/${offset * 12}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        setData(data);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  const multipliedOffset = offset * 12;

  function downloadData() {
    if (data && data.data) {
      const csvData = convertToCSV(data.data);
      downloadCSV(csvData);
    }
  }

  function convertToCSV(data) {
    const headers = [
      "No",
      "Task",
      "Role",
      "SubCounty",
      "Ward",
      "Name",
      "Contact",
      "Date",
    ];
    const rows = data.map((item, index) => [
      multipliedOffset + index + 1,
      item.Task,
      item.Role,
      item.SubCounty,
      item.Ward,
      item.Name,
      item.Phone,
      item.Date,
    ]);

    const csvContent =
      "data:text/csv;charset=utf-8," +
      [headers, ...rows].map((e) => e.join(",")).join("\n");

    return csvContent;
  }

  function downloadCSV(csvContent) {
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "reports.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const Top = styled(Card)(({ theme }) => ({
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff",
    ...theme.typography.body2,
    textAlign: "center",
    color: theme.palette.text.secondary,
    margin: "10px",
    ...theme.applyStyles("dark", {
      backgroundColor: "#1A2027",
    }),
  }));

  const iconStyle = { color: "#ffa726", fontSize: "2rem" };

  const icons = {
    Agriculture: <FaWheatAwn className="Top-icons" style={iconStyle} />,
    Livestock: <GiBullHorns className="Top-icons" style={iconStyle} />,
    Veterinary: <FaUserDoctor className="Top-icons" style={iconStyle} />,
    Fisheries: <FaFish className="Top-icons" style={iconStyle} />,
    Administration: <GrUserAdmin className="Top-icons" style={iconStyle} />,
  };

  const MyCard = ({ total, label }) => {
    return (
      <Top>
        {icons[label]}
        <Box sx={{ marginLeft: "5rem" }}>
          <Typography variant="h4" sx={{ color: "#028e37" }}>
            {total}
          </Typography>
          <Typography variant="body2">{label}</Typography>
        </Box>
      </Top>
    );
  };

  return (
    <Box sx={{ padding: "2rem" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <MyCard total={todaysData?.total} label="Agriculture" />
        <MyCard total={todaysData?.total} label="Livestock" />
        <MyCard total={todaysData?.total} label="Veterinary" />
        <MyCard total={todaysData?.total} label="Fisheries" />
        <MyCard total={todaysData?.total} label="Administration" />
      </Box>

      <Box sx={{ marginTop: "2rem" }}>
        <Grid
          container
          alignItems="center"
          spacing={2}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid item xs={12} md={6}>
            <CustomTextField
              label="Search"
              placeholder="Task | Officer | Supervisor"
              onChange={(e) => {
                if (e.target.value !== "") {
                  searchReport(e.target.value);
                } else {
                  setRefresh(!refresh);
                }
              }}
              backgroundColor="#feece2"
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", justifyContent: "flex-end", gap: "1rem" }}
          >
            <CustomTextField
              label="Start Date"
              type="date"
              value={start}
              onChange={(e) => setStart(e.target.value)}
              InputLabelProps={{ shrink: true }}
              backgroundColor="#feece2"
            />
            <CustomTextField
              label="End Date"
              type="date"
              value={end}
              onChange={(e) => setEnd(e.target.value)}
              InputLabelProps={{ shrink: true }}
              backgroundColor="#feece2"
            />
            <IconButton>
              <DownloadIcon onClick={downloadData} />
            </IconButton>
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Typography variant="h5" sx={{ color: "#028e37" }}>
          Today's Reports
        </Typography>
        {todaysData && todaysData?.data?.length > 0 ? (
          todaysData.data.map((item, i) => (
            <Top key={i} index={offset + i + 1} item={item} />
          ))
        ) : (
          <Typography variant="body2" color="textSecondary">
            <em>No data</em>
          </Typography>
        )}
      </Box>

      <Box sx={{ width: "100%", marginTop: "2rem" }}>
        <Typography variant="h5" sx={{ color: "#028e37" }} gutterBottom>
          All Reports
        </Typography>

        {data && data?.data?.length > 0 ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">S/N</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">Task</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">Role</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">SubCounty</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">Ward</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">Contact</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">Date</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.data.map((item, i) => (
                  <Item key={i} index={offset + i + 1} item={item} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="body2" color="textSecondary">
            <em>No data</em>
          </Typography>
        )}

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1rem",
          }}
        >
          <Pagination
            total={data?.total}
            limit={12}
            offset={offset}
            setOffset={setOffset}
          />
        </Box>
      </Box>
    </Box>
  );
}

function Item({ item, index }) {
  return (
    <TableRow>
      <TableCell>{index}</TableCell>
      <TableCell>{item.Task}</TableCell>
      <TableCell>{item.Role}</TableCell>
      <TableCell>{item.SubCounty}</TableCell>
      <TableCell>{item.Ward}</TableCell>
      <TableCell>
        {item.Name} - {item.Phone}
      </TableCell>
      <TableCell>{item.Date}</TableCell>
    </TableRow>
  );
}
