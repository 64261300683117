import { useEffect, useRef, useState } from "react";
import {
  Button,
  TextField as MuiTextField,
  MenuItem,
  Select as MuiSelect,
  InputLabel,
  FormControl,
  CircularProgress,
  Snackbar,
  Alert,
  Box,
  Typography,
  Grid,
  Divider,
  styled,
} from "@mui/material";
import WaveLoading from "../Util/WaveLoading";

// Styled components
const StyledTextField = styled(MuiTextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#061e06",
    },
    "&:hover fieldset": {
      borderColor: "#028e37",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#028e37",
    },
  },
  "& .MuiInputLabel-root": {
    color: "#061e06",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#028e37",
  },
}));

const StyledSelect = styled(MuiSelect)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#061e06",
    },
    "&:hover fieldset": {
      borderColor: "#028e37",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#028e37",
      borderWidth: "2px !important", // Ensure border width is applied
    },
  },
}));

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  color: "#061e06",
  "&.Mui-focused": {
    color: "#028e37",
  },
}));

export default function NewWorkplan(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const subc = {
    "": [""],
    "Mwea West": ["Mutithi", "Kangai", "Thiba", "Wamumu"],
    "Mwea East": ["Nyangati", "Murinduko", "Gathigiriri", "Tebere"],
    "Kirinyaga East": [
      "Kabare",
      "Baragwi",
      "Njukiine",
      "Ngariama",
      "Karumandi",
    ],
    "Kirinyaga West": ["Mukure", "Kiine", "Kariti"],
    "Kirinyaga Central": ["Mutira", "Kanyekini", "Kerugoya", "Inoi"],
  };
  const types = ["Extension Services", "Training", "Other"];
  const [selectedSubc, setSelectedSubc] = useState(Object.keys(subc)[0]);
  const [selectedLocation, setSelectedLocation] = useState(
    subc[selectedSubc][0]
  );

  const [fo, setFO] = useState(null);
  const [fodata, setFOData] = useState(null);
  const [su, setSU] = useState(null);
  const [sudata, setSUData] = useState(null);

  const tsk = useRef();
  const sc = useRef();
  const wd = useRef();
  const dt = useRef();
  const tp = useRef();
  const trg = useRef();

  const [body, setBody] = useState({
    Task: "",
    UserID: null,
    SupervisorID: null,
    Date: "",
    SubCounty: "",
    Ward: "",
    Type: "",
    Target: 1,
  });
  const [showing, setShowing] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setShowing(window.innerWidth >= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSubcChange = (e) => {
    setSelectedSubc(e.target.value);
    setSelectedLocation(subc[e.target.value][0]);
  };

  const handleLocationChange = (e) => {
    setSelectedLocation(e.target.value);
  };

  const searchByPhone = (role, q) => {
    fetch(`/api/mobile/seachbyphone/${role}/${q}`)
      .then((res) => (res.ok ? res.json() : Promise.reject()))
      .then((data) => {
        role === "Supervisor" ? setSU(data) : setFO(data);
      })
      .catch((e) => {});
  };

  const createDocument = () => {
    setError("");
    const d = {
      ...body,
      Task: tsk.current.value,
      SubCounty: sc.current.value,
      Ward: wd.current.value,
      Date: dt.current.value,
      Type: tp.current.value,
      Target: trg.current.value,
    };
    setBody(d);

    if (Object.values(d).some((item) => item === "" || item === null)) {
      return setError("All fields are required!");
    }
    setLoading(true);

    fetch("/api/workplan/create", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(d),
    })
      .then((response) => (response.ok ? response.json() : Promise.reject()))
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            window.location.href = "/es/Reports";
          }, 1000);
        } else {
          setError(data.error);
        }
      })
      .catch(() => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  const List = ({ item, type }) => (
    <Box
      onClick={() => {
        if (type === "fo") {
          setBody((prev) => ({ ...prev, UserID: item.UserID }));
          setFOData(item);
          setFO(null);
        } else {
          setBody((prev) => ({ ...prev, SupervisorID: item.UserID }));
          setSUData(item);
          setSU(null);
        }
      }}
      sx={{ cursor: "pointer", padding: 1, borderBottom: "1px solid gray" }}
    >
      <Typography>
        {item.Name} - {item.Phone}
      </Typography>
    </Box>
  );

  return (
    <Box className="list" p={2}>
      <Typography variant="h4">Work Plan Item</Typography>
      <Divider sx={{ my: 2 }} />
      <Box component="form" onSubmit={(e) => e.preventDefault()}>
        <StyledTextField
          inputRef={tsk}
          label="Task *"
          variant="outlined"
          fullWidth
          margin="normal"
        />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <StyledTextField
              inputRef={dt}
              type="date"
              label="Date *"
              variant="outlined"
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined" margin="normal">
              <StyledInputLabel>Select Type</StyledInputLabel>
              <StyledSelect
                inputRef={tp}
                label="Select Type"
                value={body.Type}
                onChange={(e) => setBody({ ...body, Type: e.target.value })}
              >
                {types.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </StyledSelect>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledTextField
              inputRef={trg}
              type="number"
              label="Target Farmers *"
              variant="outlined"
              fullWidth
              margin="normal"
              value={body.Target}
              onChange={(e) => setBody({ ...body, Target: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined" margin="normal">
              <StyledInputLabel>Select Subcounty</StyledInputLabel>
              <StyledSelect
                inputRef={sc}
                label="Select Subcounty"
                value={selectedSubc}
                onChange={handleSubcChange}
              >
                {Object.keys(subc).map((subcounty) => (
                  <MenuItem key={subcounty} value={subcounty}>
                    {subcounty}
                  </MenuItem>
                ))}
              </StyledSelect>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined" margin="normal">
              <StyledInputLabel>Select Location</StyledInputLabel>
              <StyledSelect
                inputRef={wd}
                label="Select Location"
                value={selectedLocation}
                onChange={handleLocationChange}
              >
                {subc[selectedSubc].map((location) => (
                  <MenuItem key={location} value={location}>
                    {location}
                  </MenuItem>
                ))}
              </StyledSelect>
            </FormControl>
          </Grid>
        </Grid>

        <Box mt={2}>
          <Typography variant="h6">Select Field Officer *</Typography>
          <Divider sx={{ my: 2 }} />
          <StyledTextField
            type="number"
            label="Phone Number"
            variant="outlined"
            fullWidth
            margin="normal"
            onChange={(e) => searchByPhone("Field Officer", e.target.value)}
          />
          {fo && (
            <Box className="search_list">
              {fo.map((item, i) => (
                <List key={i} item={item} type="fo" />
              ))}
            </Box>
          )}
          {fodata && (
            <Box
              className="item"
              sx={{ mt: 2, border: "1px solid gray", padding: 2 }}
            >
              <Typography variant="h6">Selected Field Officer</Typography>
              <Divider sx={{ my: 2 }} />
              <Typography>{fodata.Name}</Typography>
              <Typography>{fodata.Phone}</Typography>
              <Button
                variant="outlined"
                color="error"
                onClick={() => {
                  setBody((prev) => ({ ...prev, UserID: null }));
                  setFOData(null);
                }}
              >
                Remove
              </Button>
            </Box>
          )}
        </Box>

        <Box mt={2}>
          <Typography variant="h6">Assign Supervisor *</Typography>
          <Divider sx={{ my: 2 }} />
          <StyledTextField
            type="number"
            label="Phone Number"
            variant="outlined"
            fullWidth
            margin="normal"
            onChange={(e) => searchByPhone("Supervisor", e.target.value)}
          />
          {su && (
            <Box className="search_list">
              {su.map((item, i) => (
                <List key={i} item={item} type="su" />
              ))}
            </Box>
          )}
          {sudata && (
            <Box
              className="item"
              sx={{ mt: 2, border: "1px solid gray", padding: 2 }}
            >
              <Typography variant="h6">Selected Supervisor</Typography>
              <Divider sx={{ my: 2 }} />
              <Typography>{sudata.Name}</Typography>
              <Typography>{sudata.Phone}</Typography>
              <Button
                variant="outlined"
                color="error"
                onClick={() => {
                  setBody((prev) => ({ ...prev, SupervisorID: null }));
                  setSUData(null);
                }}
              >
                Remove
              </Button>
            </Box>
          )}
        </Box>

        {error && (
          <Snackbar
            open={!!error}
            autoHideDuration={6000}
            onClose={() => setError("")}
          >
            <Alert onClose={() => setError("")} severity="error">
              {error}
            </Alert>
          </Snackbar>
        )}

        <Box mt={2} display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            onClick={createDocument}
            disabled={loading}
            endIcon={loading && <CircularProgress size={24} />}
            sx={{
              backgroundColor: "#061e06",
              width: "80%",
              justifyContent: "center",
              borderRadius: 0,
              "&:hover": {
                backgroundColor: "darkorange",
              },
            }}
          >
            Submit
          </Button>
        </Box>
      </Box>
      {loading && <WaveLoading />}
    </Box>
  );
}
