import React from "react";
import { Box, Typography, Button, IconButton } from "@mui/material";
import {
  Delete as DeleteIcon,
  Refresh as RefreshIcon,
} from "@mui/icons-material";

function formatDate(inputDate) {
  const date = new Date(inputDate);

  // Format the day with the appropriate suffix
  const day = date.getDate();
  const dayWithSuffix = getDayWithSuffix(day);

  const options = { year: "numeric", month: "short" };
  const formattedDate = date.toLocaleDateString("en-US", options);

  return `${dayWithSuffix} ${formattedDate}`;
}

function getDayWithSuffix(day) {
  if (day >= 11 && day <= 13) {
    return `${day}th`;
  }

  switch (day % 10) {
    case 1:
      return `${day}st`;
    case 2:
      return `${day}nd`;
    case 3:
      return `${day}rd`;
    default:
      return `${day}th`;
  }
}

export default function SelectedUser(props) {
  function updateUser(status) {
    props.setLoading(true);
    fetch(`/api/${props.url}/${props?.userDetails?.UserID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ Status: !status }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        props.setLoading(false);
        props.setRefresh(!props.refresh);
        window.location.reload();
      })
      .catch((err) => {
        props.setLoading(false);
      });
  }

  function deleteUser() {
    props.setLoading(true);
    fetch(`/api/${props.url}/${props?.userDetails?.UserID}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        props.setLoading(false);
        props.setRefresh(!props.refresh);
        window.location.reload();
      })
      .catch((err) => {
        props.setLoading(false);
      });
  }

  console.log(props?.activetab);

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        User Details
      </Typography>
      <Typography variant="body1">
        <strong>Name:</strong> {props?.userDetails?.Name}
      </Typography>
      <Typography variant="body1">
        <strong>Email:</strong> {props?.userDetails?.Email}
      </Typography>
      <Typography variant="body1">
        <strong>Phone:</strong> {props?.userDetails?.Phone}
      </Typography>

      <Typography variant="body1">
        <strong>Directorate:</strong> {props?.userDetails?.Directorate}
      </Typography>

      {props.activeTab === "Mobile Users" && (
        <Typography variant="body1">
          <strong>Duty Station:</strong> {props?.userDetails?.DutyStation}
        </Typography>
      )}

      {props.activeTab === "Mobile Users" && (
        <Typography variant="body1">
          <strong>Deployment:</strong> {props?.userDetails?.Deployment}
        </Typography>
      )}

      <Typography variant="body1">
        <strong>Role:</strong> {props?.userDetails?.Role}
      </Typography>
      <Typography variant="body1">
        <strong>Level:</strong> {props?.userDetails?.Level}
      </Typography>
      <Typography variant="body1">
        <strong>Level Name:</strong> {props?.userDetails?.LevelName}
      </Typography>
      <Typography variant="body1">
        <strong>Status:</strong>{" "}
        {props?.userDetails?.Status ? "Active" : "Disabled"}
      </Typography>
      <Typography variant="body1">
        <strong>Date Created:</strong>{" "}
        {formatDate(props?.userDetails?.createdAt)}
      </Typography>

      <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
        <Button
          variant="contained"
          color={props?.userDetails?.Status ? "warning" : "success"}
          onClick={() => updateUser(props?.userDetails?.Status)}
          startIcon={<RefreshIcon />}
        >
          {props?.userDetails?.Status ? "Deactivate" : "Activate"}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={deleteUser}
          startIcon={<DeleteIcon />}
        >
          Delete
        </Button>
      </Box>
    </Box>
  );
}
