import React, { useState } from "react";
import MyMap from "./MyMap";
import Reports from "./Reports";
import { Box, Tabs, Tab, Typography } from "@mui/material";

export default function ActivitiesHome() {
  const [activeTab, setActiveTab] = useState("List");

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box className="reports">
      <Box className="bar" sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          variant="standard"
          textColor="inherit"
          indicatorColor="primary"
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: "rgba(255, 167, 38, 0.9)",
            },
          }}
        >
          <Tab label="List" value="List" />
          <Tab label="Map" value="Map" />
        </Tabs>
      </Box>

      {/* Tab Content */}
      <Box sx={{ padding: 2 }}>
        {activeTab === "List" && (
          <Typography component="div">
            <Reports />
          </Typography>
        )}
        {activeTab === "Map" && (
          <Typography component="div">
            <MyMap />
          </Typography>
        )}
      </Box>
    </Box>
  );
}
