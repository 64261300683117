import { useState } from "react";
import { Box, Tabs, Tab, Typography } from "@mui/material";
import NewPost from "./NewsPost";
import NewStakeholder from "./NewStakeholder";
import NewProject from "./NewProject";

export default function PortalUpdates() {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box >
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        variant="standard"
        textColor="inherit"
        indicatorColor="primary"
        sx={{
          "& .MuiTabs-indicator": {
            backgroundColor: "rgba(255, 167, 38, 0.9)",
          },
        }}
      >
        <Tab label="News" />
        <Tab label="Stakeholders" />
        <Tab label="Projects" />
      </Tabs>

      <Box mt={3}>
        {activeTab === 0 && (
          <Typography component="div">
            <NewPost />
          </Typography>
        )}
        {activeTab === 1 && (
          <Typography component="div">
            <NewStakeholder />
          </Typography>
        )}
        {activeTab === 2 && (
          <Typography component="div">
            <NewProject />
          </Typography>
        )}
      </Box>
    </Box>
  );
}
