import { useState } from "react";
import { Box, Typography, Tabs, Tab, Divider } from "@mui/material";
import Workplan from "./Workplan";
import NewWorkplan from "./NewWorkplan";
import Reports from "./Reports";
import MyMap from "./MyMap";

export default function ReportsHome(props) {
  const [activeTab, setActiveTab] = useState("Reports");

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box className="reports" sx={{ width: "100%", bgcolor: "white" }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          variant="standard"
          textColor="inherit"
          indicatorColor="primary"
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: "rgba(255, 167, 38, 0.9)",
            },
          }}
        >
          <Tab value="Workplan" label="Workplan" />
          <Tab value="New" label="New" />
          <Tab value="Reports" label="Reports" />
          <Tab value="Map" label="Map" />
        </Tabs>
      <Divider />
      <Box sx={{ padding: 2 }}>
        {activeTab === "Workplan" && <Workplan />}
        {activeTab === "New" && <NewWorkplan />}
        {activeTab === "Reports" && <Reports />}
        {activeTab === "Map" && <MyMap />}
      </Box>
    </Box>
  );
}
