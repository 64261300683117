import React, { useEffect, useRef, useState } from "react";
import { Box, Card, Divider, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import "../../Styles/newstats.scss";
import { CachedOutlined } from "@mui/icons-material";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import TaskRoundedIcon from "@mui/icons-material/TaskRounded";
import GaugeChart from "react-gauge-chart";
import WaveLoading from "../Util/WaveLoading";
import { BarChart } from "@mui/x-charts/BarChart";
import ActivitiesTable from "../Stats/ActivitiesTable";

export default function NewStats() {
  const today = new Date();

  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [staff, setStaff] = useState(null);
  const [wrdstaff, setWrdStaff] = useState(null);
  const [sbcounty, setSbCounty] = useState(null);
  const [start, setStart] = useState(
    `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
  );
  const [end, setEnd] = useState(
    `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
  );
  const [activities, setActivities] = useState(null);
  const [offset, setOffset] = useState(0);
  const [activeToday, setActiveToday] = useState(0);

  useEffect(() => {
    if (start !== "" && end !== "") {
      getData();
    }
  }, [start, end, refresh, offset]);


  useEffect(() => {
    setLoading(true);
    fetch(`/api/activity/paginated/${start}/${end}/${offset * 12}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        setActivities(data);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [refresh, offset, start, end, offset]);

  useEffect(() => {
    setLoading(true);
    const today = new Date();

    fetch(
      `/api/mobile/active/${today.getFullYear()}-${
        today.getMonth() + 1
      }-${today.getDate()}`,
      {
        method: "GET",
      }
    )
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        setActiveToday(data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    fetch(`/api/mobile/staff/sbcounty`, {
      method: "GET",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        setStaff(data);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (staff && Array.isArray(staff.sb) && staff.sb.length > 0) {
      setSbCounty(staff.sb[0]?.name);
    }
  }, [staff]);

  useEffect(() => {
    if (sbcounty) {
      fetch(`/api/mobile/staff/ward/${sbcounty}`, {
        method: "GET",
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setWrdStaff(data);
        })
        .catch((err) => {});
    }
  }, [sbcounty]);

  function getData() {
    setLoading(true);
    fetch(`/api/activity/home/charts/${start}/${end}/${offset * 12}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("Failed!");
      })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  function search(q) {
    setLoading(true);
    fetch(`/api/activity/search/${q}/${offset * 12}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        setActivities(data);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (start !== "" && end !== "") {
      getData();
    }
  }, [start, end, offset]);

  const act = useRef();

  const handleScroll = () => {
    if (act.current) {
      act.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Box className="landing">
      <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }} className="landing__heading">
        <Grid item size={{ xs: 4, sm: 4, md: 6 }}>
          <Typography className="landing__heading--text" variant="h5">
            Kirinyaga Dashboard
          </Typography>
        </Grid>
        <Grid
          item
          size={{ xs: 4, sm: 4, md: 6 }}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <CachedOutlined
            className="landing__heading--icon"
            onClick={() => {
              setRefresh(!refresh);
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        columns={{ xs: 4, sm: 8, md: 12 }}
        sx={{ marginBottom: "1rem" }}
      >
        <Grid item size={{ xs: 4, sm: 4, md: 6 }}>
          <Card
            sx={{
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Box sx={{ width: "16px", backgroundColor: "orange" }}></Box>
            <Typography p={1} variant="h6">
              Daily Perfomance
            </Typography>
          </Card>
          <Grid container spacing={2} columns={16} sx={{ marginTop: "1rem" }}>
            <Grid item size={8}>
              <Card
                sx={{
                  borderRadius: "8px",
                  boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.2)",
                  width: "100%",
                  marginBottom: "1rem",
                  padding: "1rem",
                  display: "flex",
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.location.href = "/active";
                }}
              >
                <PeopleRoundedIcon className="icon" />
                <Box className="details">
                  <Typography
                    variant="h2"
                    sx={{
                      color: activeToday
                        ? activeToday.data > 0
                          ? "green"
                          : "red"
                        : "red",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    {activeToday ? activeToday.data : 0}
                  </Typography>
                  <Typography variant="h6">Today's active staff</Typography>
                </Box>
              </Card>
              <Card
                sx={{
                  borderRadius: "8px",
                  boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.2)",
                  width: "100%",
                  padding: "1rem",
                  display: "flex",
                  gap: "3rem",
                  cursor: "pointer",
                }}
                onClick={handleScroll}
              >
                <TaskRoundedIcon className="icon" />
                <Box className="details">
                  <Typography
                    variant="h2"
                    sx={{
                      color: activeToday
                        ? activeToday.data > 0
                          ? "green"
                          : "red"
                        : "red",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    {data ? data.Tasks : 0}
                  </Typography>
                  <Typography variant="h6">Today's Activities</Typography>
                </Box>
              </Card>
            </Grid>
            <Grid item size={8}>
              <Paper sx={{ padding: "10px" }}>
                <Typography
                  className="text"
                  variant="body1"
                  sx={{ marginBottom: "7rem", marginTop: "1rem" }}
                >
                  Today's Activities vs Active Staff
                </Typography>
                <GaugeChart
                  className="gauge"
                  id="gauge-chart2"
                  nrOfLevels={3}
                  colors={["red", "orange", "green"]}
                  arcWidth={0.3}
                  percent={
                    data
                      ? (
                          parseInt(activeToday?.data) / parseInt(data?.Tasks)
                        ).toFixed(2)
                      : 0
                  }
                  textColor="gray"
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item size={{ xs: 4, sm: 4, md: 6 }}>
          <Card
            sx={{
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Box sx={{ width: "16px", backgroundColor: "orange" }}></Box>
            <Typography p={1} variant="h6">
              Distribution of Staff Activities by Sub County
            </Typography>
          </Card>
          <Paper sx={{ marginTop: "1rem" }}>
            <BarChart
              title="Sub County Activities"
              xAxis={[
                {
                  scaleType: "band",
                  data: data ? data.SubCounty.map((item) => item.name) : [],
                },
              ]}
              series={[
                {
                  data: data ? data.SubCounty.map((item) => item.value) : [],
                },
              ]}
              height={290}
            />
          </Paper>
        </Grid>
      </Grid>
      <Divider sx={{ height: "4px", marginBottom: "1rem" }} />
      <Grid container spacing={2} columns={16} sx={{ marginBottom: "1rem" }}>
        <Grid size={8}>
          <Card
            sx={{
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Box sx={{ width: "16px", backgroundColor: "orange" }}></Box>
            <Typography p={1} variant="h6">
              Distribution of Staff Activities by Type
            </Typography>
          </Card>
          <Paper sx={{ marginTop: "1rem" }}>
            <BarChart
              title="Types of Activities"
              xAxis={[
                {
                  scaleType: "band",
                  data: data ? data.Type.map((item) => item.name) : [],
                },
              ]}
              series={[
                {
                  data: data ? data.Type.map((item) => item.value) : [],
                },
              ]}
              height={500}
            />
          </Paper>
        </Grid>
        <Grid size={8}>
          <Card
            sx={{
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Box sx={{ width: "16px", backgroundColor: "orange" }}></Box>
            <Typography p={1} variant="h6">
              Distribution of Staff Activities by Ward
            </Typography>
          </Card>
          <Paper sx={{ marginTop: "1rem" }}>
            <BarChart
              title="Activities by Wards"
              xAxis={[
                {
                  scaleType: "band",
                  data: data ? data.Ward.map((item) => item.name) : [],
                },
              ]}
              series={[
                {
                  data: data ? data.Ward.map((item) => item.value) : [],
                },
              ]}
              height={500}
            />
          </Paper>
        </Grid>
      </Grid>

      <Divider sx={{ height: "4px", marginBottom: "1rem" }} />

      <Grid container spacing={2} columns={16} sx={{ marginBottom: "1rem" }}>
        <Grid size={8}>
          <Card
            sx={{
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Box sx={{ width: "16px", backgroundColor: "orange" }}></Box>
            <Typography p={1} variant="h6">
              Number of staff at Sub County Level
            </Typography>
          </Card>
          <Paper sx={{ marginTop: "1rem" }}>
            {staff && staff.sb ? (
              <BarChart
                title="Types of Activities"
                xAxis={[
                  {
                    scaleType: "band",
                    data: staff?.sb ? staff.sb.map((item) => item.name) : [],
                  },
                ]}
                series={[
                  {
                    data: staff?.sb ? staff.sb.map((item) => item.value) : [],
                  },
                ]}
                height={500}
              />
            ) : (
              <p>No data available</p>
            )}
          </Paper>
        </Grid>
        <Grid size={8}>
          <Card
            sx={{
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Box sx={{ width: "16px", backgroundColor: "orange" }}></Box>
            <Typography p={1} variant="h6">
              Number of staff at Ward Level
            </Typography>
          </Card>
          <Paper sx={{ marginTop: "1rem" }}>
            {wrdstaff && wrdstaff.wrd ? (
              <BarChart
                title="Types of Activities"
                xAxis={[
                  {
                    scaleType: "band",
                    data: wrdstaff?.wrd
                      ? wrdstaff.wrd.map((item) => item.name)
                      : [],
                  },
                ]}
                series={[
                  {
                    data: wrdstaff?.wrd
                      ? wrdstaff.wrd.map((item) => item.value)
                      : [],
                  },
                ]}
                height={500}
              />
            ) : (
              <p>No data available</p>
            )}
          </Paper>
        </Grid>
      </Grid>

      <Divider sx={{ height: "4px", marginBottom: "1rem" }} />

      <Grid
        container
        spacing={2}
        columns={16}
        onClick={() => act.current.scrollIntoView({ behavior: "smooth" })}
      >
        <Card
          sx={{
            display: "grid",
            gridTemplateColumns: "auto 1fr",
            boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.2)",
            width: "100%",
            cursor: "pointer",
          }}
        >
          <Box sx={{ width: "16px", backgroundColor: "orange" }}></Box>
          <Typography p={1} variant="h6">
            Todays Activities
          </Typography>
        </Card>
      </Grid>

      <ActivitiesTable activities={activities} offset={offset} ref={act} />

      {loading && <WaveLoading />}
    </Box>
  );
}

const Item = (props) => {
  const [color, setColor] = useState("#fff");
  const [clicked, setClicked] = useState(false);

  const capitalizeFirstLetter = (string) => {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  };

  useEffect(() => {
    if ((props.index + 1) % 2 === 0) {
      setColor("#feece2");
    }
  }, [props.index]);

  return (
    <Paper
      elevation={clicked ? 3 : 1}
      onClick={() => setClicked(true)}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 2,
        backgroundColor: color,
        marginBottom: 1,
        cursor: "pointer",
      }}
    >
      <Typography variant="body1">{props.index}</Typography>

      <Box>
        <Typography variant="h6">
          {capitalizeFirstLetter(props?.item?.Task)}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {capitalizeFirstLetter(props?.item?.Venue)}
        </Typography>
      </Box>

      <Box>
        <Typography variant="body2" color="textSecondary">
          {capitalizeFirstLetter(props?.item?.Name)}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {props.item.Phone}
        </Typography>
      </Box>

      <Typography variant="body1">{props.item.Date}</Typography>
      <Typography variant="body1">{props.item.Level}</Typography>
      <Typography variant="body1">{props.item.LevelName}</Typography>
    </Paper>
  );
};
