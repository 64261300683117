import { Box, ListItem, ListItemText, Typography } from "@mui/material";

const ReportItem = ({ index, item }) => {
  return (
    <ListItem divider>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <ListItemText
            primary={
              <Typography
                variant="body1"
                sx={{ color: "#028e37", marginBottom: "0.5rem" }}
              >
                <span
                  style={{
                    backgroundColor: "#feece2",
                    padding: "0.5rem",
                    borderRadius: "4px",
                    marginRight: "0.5rem",
                  }}
                >
                  {`${index}`}
                </span>
                <strong>{item.Task}</strong>
              </Typography>
            }
            secondary={
              <>
                <Typography variant="body2" color="textSecondary">
                  {item.Type}
                </Typography>
                {/* <Typography variant="body2" color="textSecondary">
                  {item.Description}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {item.Venue}
                </Typography> */}
              </>
            }
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="caption"
            color="textSecondary"
            sx={{
              color: "#028e37",
              backgroundColor: "#feece2",
              padding: "0.5rem",
              borderRadius: "4px",
            }}
          >
            {item.Date}
          </Typography>
        </Box>
      </Box>
    </ListItem>
  );
};

export default ReportItem;
