import React, { useEffect, useState } from "react";
import { Box, ThemeProvider, createTheme } from "@mui/material";
import Settings from "../components/Settings/Settings";
import UserHome from "../components/Users/UserHome";
import Header from "../components/Util/Header";
import Navigation from "../components/Util/Navigation";
import PortalUpdates from "../components/PortalUpdates/PortalUpdates";
import ActivitiesHome from "../components/Activities/ActivitiesHome";
import ReportsHome from "../components/Reports/ReportsHome";
import NewStats from "../components/Stats/NewStats";
import ActiveUsers from "../components/Stats/ActiveUsers";
import { isMobile } from "react-device-detect";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
      light: "#63a4ff",
    },
    secondary: {
      main: "#dc004e",
    },
    background: {
      default: "#fff",
    },
  },
});

export default function Home(props) {
  const [showing, setShowing] = useState(true);
  const pathname = window.location.pathname.split("/");

  const handleResize = () => {
    if (window.innerWidth < 1024) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box className="home">
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: isMobile
              ? "1fr"
              : showing
              ? "250px 1fr"
              : "auto 1fr",
            height: "100vh",
          }}
          className="main"
        >
          <Box
            sx={{
              display: isMobile ? "none" : "block",
            }}
            className="left_panel"
          >
            <Navigation showing={showing} setShowing={setShowing} />
          </Box>
          <Box className="right_panel">
            <Header showing={showing} setShowing={setShowing} />
            <Box className="full" sx={{ padding: 2 }}>
              {pathname[1] === "" && <NewStats showing={showing} />}
              {pathname[1] === "portal-updates" && <PortalUpdates />}
              {pathname[1] === "users" && <UserHome />}
              {pathname[1] === "activities" && <ActivitiesHome />}
              {pathname[1] === "es" && <ReportsHome />}
              {pathname[1] === "active" && <ActiveUsers />}
              {pathname[1] === "settings" && <Settings />}
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
